import { useEffect } from "react"
import { navigate } from "gatsby"

import { PAYPERKS_DE_TRANSITION_URL } from "../constants/pageUrls"

const DirectExpressTransitionRedirectPage = () => {
  useEffect(() => {
    navigate(PAYPERKS_DE_TRANSITION_URL)
  }, [])

  return null
}

export default DirectExpressTransitionRedirectPage
